var $_cart = $('.cart');

function appendToCart(data) {
    if (data) {
        var count = 0;
        $.each(data, function(rowId, item) {
            var newItem = '<li class="item">';
            newItem += '<a href="' + _baseurl + '/product/' + item.id +'" class="cb">';
            newItem += '<div class="img-wrap"><img src="'+ item.options.thumb +'" class="img" /></div>';
            newItem += '<div class="info cb">';
            newItem += '<div class="title">'+ item.name +'</div>';
            newItem += '<div class="amount">'+ item.qty + '</div>'
            newItem += '<span class="total currency">'+ item.subtotal +'</span>';
            newItem += '</div></a>';
            newItem += '<div class="delete">';
            newItem += '<button class="btn btn-small-delete fa fa-close" onClick="javascript:cartRemove(\''+ rowId +'\');"></button>';
            newItem += '</div>';
            newItem += '</div>';
            newItem += '</li>';

            $_cart.append(newItem);
            count++;
        });

        $_cart.prev('.dropdown-toggle').find('.badge').text(count);
        $_cart.append('<a href="'+ _baseurl + '/checkout' +'" class="btn btn-checkout">前往下單</a>');
    }
}

function initialCart() {
    var emptyState = '';

    $_cart.prev('.dropdown-toggle').find('.badge').text('0');

    $.get(_baseurl + '/cart/list', function(data) {

        $_cart.empty();

        if (! $.isEmptyObject(data)) {
            appendToCart(data);
        } else {
            $_cart.append(emptyState);
            $(document).trigger('cartEmpty');
        }

    });
}

function addToCart(id, amount) {
    $.post(_baseurl + '/cart/add/' + id, {
        'amount': amount,
        '_token': _token
    }, function(data) {

        $_cart.empty();

        if (data) {
            appendToCart(data)
            $(document).trigger('cartAdd', data);
        } else {
            initialCart();
        }
    });
}

function cartUpdate(rowId, amount) {
    $.post(_baseurl + '/cart/update/' + rowId, {
        'amount': amount,
        '_token': _token
    }, function(data) {

        $_cart.empty();

        if (data) {
            appendToCart(data)
            $(document).trigger('cartUpdate', data);
        } else {
            initialCart();
        }
    });
}

function cartRemove(rowId) {
    var url = _baseurl + '/cart/remove';

    if (rowId !== null) {
        url += '/' + rowId;
    }

    $.get(url, function(data) {
        initialCart();
    });

    $(document).trigger('cartRemove', rowId);
}

initialCart();

wow = new WOW({
    animateClass: 'animated',
    offset: 100,
    mobile: false
});
wow.init();
