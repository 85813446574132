(function() {
    // - Listenters
    document.addEventListener( 'DOMContentLoaded', _init, false );

    // - Handlers
    function _init() {

        $( '[role="toggle-menu"] [type="button"]' ).bind( 'click.menuToggle', ( e ) => menuToggle( e ) );
        $( '.btn-product-act' ).bind( 'click.cartAdd', ( e ) => cartAdd( e ) );
        $( '.article-pages .pages' )
            .scrollLeft( '.pg.now' )
            .on( 'mousewheel', ( e ) => pagesScroll( e ) );
        // $( '[role="inp-num"]' ).on( 'mousewheel', ( e ) => inpNumWheel( e ) );
        $( '#rec-equal' ).change( ( e ) => recEqualize( e ) );

    }

    function menuToggle( e ) {
        $( e.currentTarget )
            .parents( '.item' )
            .toggleClass( 'open' )
            .children('[role="toggle-content"]')
            .slideToggle( 150 );
    }

    function cartAdd( e ) {
        e.preventDefault();

        var $product_id = $(e.target).data('product-id');
        addToCart($product_id, 1);
    }

    function pagesScroll( e ) {
        e.preventDefault();
        if ( e.deltaY > 0 ) $( e.currentTarget ).animate( { scrollLeft: '-=100' }, 10 );
        else if ( e.deltaY < 0 ) $( e.currentTarget ).animate( { scrollLeft: '+=100' }, 10 );
    }

    function inpNumWheel( e ) {
        e.preventDefault();
        var val = parseInt( $( e.currentTarget ).val() ),
            min = $( e.currentTarget ).attr( 'min' ),
            max = $( e.currentTarget ).attr( 'max' );
        if ( e.deltaY > 0 && !( min && val <= min ) ) $( e.currentTarget ).val( val - 1 );
        else if ( e.deltaY < 0 && !( max && val >= max ) ) $( e.currentTarget ).val( val + 1 );
    }

    function recEqualize( e ) {
        if ( $( e.currentTarget ).prop( 'checked' ) ) {

            $( '#rec-name' )
                .val( $( '#buy-name' ).val() )
                .attr( 'readonly', true );

            $( '#buy-name' ).bind( 'change.sync', ( e ) =>
                $( '#rec-name' ).val( $( e.currentTarget ).val() )
            );

        } else {

            $( '#rec-name' ).removeAttr( 'readonly' );
            $( '#buy-name' ).unbind( 'change.sync' );

        }
    }

}) ($);
